import { TenantAvailabilityState } from "./service-proxies/service-proxies";

export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}
export class AppTenantAvailabilityState {
    static Available: number = TenantAvailabilityState.Available;
    static InActive: number = TenantAvailabilityState.InActive;
    static NotFound: number = TenantAvailabilityState.NotFound;
}
